import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { BentoMenu } from '../../../components/Icons'
import * as S from '../styles'

type LeftZonProps = {
  onClick: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
}

export function LeftZone({ onClick }: LeftZonProps): React.ReactElement<LeftZonProps> {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            version
          }
        }
      }
    `,
  )

  return (
    <S.LeftZoneWrapper>
      <S.Menu onClick={onClick}>
        <BentoMenu width={32} height={32} />
      </S.Menu>
      <S.Title>
        <S.Versioning>version {site.siteMetadata.version}</S.Versioning>
      </S.Title>
    </S.LeftZoneWrapper>
  )
}
