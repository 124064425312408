import * as React from 'react'
//@ts-ignore
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

import { logos } from '@ulule/owl-kit-components/dist/next.esm'

import { Menu } from '../Menu/'
import { LeftZone } from './components/LeftZone'
import { RightZone } from './components/RightZone'

import * as S from './styles'

export function Header(): React.ReactElement {
  const targetRef = React.useRef<HTMLDivElement>(null)
  const [open, setOpen] = React.useState(false)

  React.useEffect(() => {
    if (open) {
      disableBodyScroll(targetRef.current)
      document.addEventListener('keydown', handleEscPress)
    } else {
      document.addEventListener('keydown', handleOpenShortcutPress)
    }
    return () => {
      if (open) {
        enableBodyScroll(targetRef.current)
        document.removeEventListener('keydown', handleOpenShortcutPress)
      } else {
        document.removeEventListener('keydown', handleEscPress)
      }
    }
  }, [open])

  return (
    <>
      <S.HeaderWrapper>
        <S.LeftZone>
          <LeftZone onClick={handleOpen} />
        </S.LeftZone>
        <S.CenterZone>
          <a href="/">
            <logos.plume.HorizontalLogo height="38" />
          </a>
        </S.CenterZone>
        <S.RightZone>
          <RightZone />
        </S.RightZone>
      </S.HeaderWrapper>
      <S.MenuWrapper ref={targetRef} open={open}>
        <Menu onClickClose={handleClose} />
      </S.MenuWrapper>
    </>
  )

  function handleOpen(event: React.MouseEvent | KeyboardEvent): void {
    event.preventDefault()
    setOpen(true)
  }

  function handleClose(event: React.MouseEvent | KeyboardEvent): void {
    event.preventDefault()
    setOpen(false)
  }

  function handleEscPress(event: KeyboardEvent): void {
    if (event.keyCode === 27) {
      handleClose(event)
    }
  }

  function handleOpenShortcutPress(event: KeyboardEvent): void {
    if (event.metaKey && event.shiftKey && event.key === 'O') {
      handleOpen(event)
    }
  }
}
