import * as React from 'react'

export function BackgroundPattern(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%" height="100%" preserveAspectRatio="none">
      <pattern id="pattern" x="0" y="0" width="16" height="16" patternUnits="userSpaceOnUse">
        <rect fill="rgba(0, 0, 0, 0.06)" x="0" width="8" height="8" y="0" />
        <rect fill="rgba(0, 0, 0, 0.06)" x="8" width="8" height="8" y="8" />
      </pattern>
      <rect fill="url(#pattern)" x="0" y="0" width="100%" height="100%" />
    </svg>
  )
}
