import styled from 'styled-components'

export const MenuSide = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  @media (min-width: 1024px) {
    flex: 0 1 256px;
  }
`

export const MenuMain = styled.div`
  flex: 1 1 0;
`

export const MenuWrapper = styled.div`
  position: relative;
  padding: 64px 32px;
  color: #ffffff;
  display: flex;
  flex-direction: column;

  ${MenuSide} + ${MenuMain} {
    margin-top: 16px;
  }

  @media (min-width: 1024px) {
    padding: 64px;
    flex-direction: row;

    ${MenuSide} + ${MenuMain} {
      margin-top: 0;
      margin-left: 16px;
    }
  }
`

export const MenuSideSection = styled.div``

export const ResultsWrapper = styled.div`
  margin-top: 32px;
`

export const PagesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  > li {
    padding: 8px 0;
  }

  a {
    color: #ffffff;
    font-weight: 300;
    text-decoration: none;

    :hover {
      opacity: 0.4;
    }
  }
`

export const NestedPageList = styled(PagesList)`
  padding-left: 24px;
`

export const MenuButton = styled.span`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  color: #ffffff;
  transition: all 200ms ease-in-out;

  svg {
    color: currentColor;
  }

  &:hover {
    opacity: 0.4;
  }
`

export const MenuTitle = styled.span`
  display: block;
  font-size: 24px;
  font-weight: 700;
  color: #ffffff;
  margin: 16px 0;
  opacity: 0.4;
`

export const ComponentsList = styled.ul`
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0;
  margin: -10px;

  > li {
    list-style-type: none;
    padding: 10px;
    width: 50%;
  }

  @media (min-width: 768px) {
    & > li {
      width: 33%;
    }
  }

  @media (min-width: 1024px) {
    & > li {
      width: 33%;
    }
  }

  @media (min-width: 1280px) {
    & > li {
      width: 20%;
    }
  }
`
