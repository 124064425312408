import styled, { css } from 'styled-components'

import { BREAKPOINTS, COLORS, FONTS } from '@ulule/owl-kit-components/dist/next.esm'

export const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;

  align-items: center;
  background-color: ${COLORS.PRIMARY_WHITE};
  border-bottom: 3px solid ${props => COLORS.primary(props)};
  box-sizing: border-box;
  color: ${COLORS.PRIMARY_BLACK};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-family: ${FONTS.PRIMARY_FONT};
  font-size: 13px;
  font-weight: 500;
  height: 60px;
  justify-content: space-between;
  padding: 0 20px;
`

export const MenuWrapper = styled.div<{ open: boolean }>`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${COLORS.PRIMARY_BLUE};
  z-index: 150;
  overflow-y: scroll;

  ${({ open }) => {
    if (!open) {
      return css`
        display: none;
      `
    }
  }}
`

export const Title = styled.h1`
  font-family: ${FONTS.EMPHASIS_FONT};
  font-size: 26px;
  font-weight: 900;
  font-style: italic;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  line-height: 1;
`

export const Menu = styled.span`
  transition: all 200ms ease-in-out;
  cursor: pointer;

  svg {
    color: currentColor;
  }

  &:hover {
    transform: scale(1.1);
    color: ${COLORS.PRIMARY_BLUE_HOVER};
  }

  &:active {
    transform: scale(0.7);
  }
`

export const Versioning = styled.span`
  display: block;
  font-size: 11px;
  font-weight: 500;
  font-style: normal;
`

export const LeftZoneWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.PRIMARY_BLACK};

  ${Title} {
    display: none;
  }

  @media (min-width: 1024px) {
    ${Title} {
      display: block;
      margin-left: 20px;
    }
  }
`

export const GithubLink = styled.a`
  display: flex;
  align-items: center;
  font-size: 15px;
  color: ${COLORS.PRIMARY_BLACK};
  text-decoration: none;

  &:hover {
    color: ${COLORS.PRIMARY_BLUE_HOVER};
  }

  span:first-child {
    display: none;
  }

  svg {
    color: currentColor;
    width: 28px;
    height: 28px;
  }

  @media (min-width: 1024px) {
    span:first-child {
      display: block;
      margin-right: 4px;
    }

    svg {
      width: 22px;
      height: 22px;
    }
  }
`

export const RightZoneWrapper = styled.div`
  @media (min-width: 1024px) {
    ${Title} {
      display: block;
      margin-left: 20px;
    }
  }
`

const Zone = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  height: 100%;
`

export const LeftZone = styled(Zone)`
  justify-content: flex-start;

  @media screen and ${BREAKPOINTS.MOBILE_L} {
    flex: 1;
  }
`

export const CenterZone = styled(Zone)`
  justify-content: center;

  a {
    color: currentColor;
    text-decoration: none;
  }
`

export const RightZone = styled(Zone)`
  justify-content: flex-end;

  @media screen and ${BREAKPOINTS.MOBILE_L} {
    max-width: initial;
  }
`
