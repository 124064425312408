/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import * as React from 'react'
import styled from 'styled-components'
import { MDXProvider } from '@mdx-js/react'

import { COLORS } from '@ulule/owl-kit-components/dist/next.esm'

import { GlobalStyle } from './GlobalStyle'
import { PrismStyle } from './PrismStyle'
import { Header } from './Header'
import { Blockquote, Paragraph } from './style'
import { CodeBlock } from '../components/CodeBlock'

import 'sanitize.css'

const AppWrapper = styled.div`
  max-width: 100vw;
  overflow: hidden;
  padding-top: 60px;
`

const Main = styled.main`
  min-height: calc(100vh - 60px);
  width: 100%;
  margin: 64px 0 0;
  padding: 0 32px 64px;

  @media (min-width: 1024px) {
    margin: 64px auto 0;
    padding: 0 64px 128px;
  }
`

const Footer = styled.footer`
  font-family: sofia-pro, sans-serif;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  color: ${COLORS.GREY_SHADE_3};
  text-align: center;
  padding: 40px 0;

  a {
    color: ${COLORS.PRIMARY_BLACK};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`

const components = {
  code: CodeBlock,
  blockquote: Blockquote,
  p: Paragraph,
}

type LayoutProps = {
  children: React.ReactNode
}

export function Layout({ children }: LayoutProps): React.ReactElement<LayoutProps> {
  return (
    <AppWrapper>
      <GlobalStyle />
      <PrismStyle />
      <Header />
      <Main>
        <MDXProvider components={components}>{children}</MDXProvider>
      </Main>
      <Footer>
        © Ulule {new Date().getFullYear()}, Built with
        {` `}
        <a href="https://www.gatsbyjs.org">Gatsby</a>
      </Footer>
    </AppWrapper>
  )
}
