import styled, { css } from 'styled-components'

import { COLORS } from '@ulule/owl-kit-components/dist/next.esm'

function flagColor(flag: string) {
  switch (flag) {
    case 'experimental':
      return css`
        background-color: ${COLORS.SECONDARY_PURPLE};
        color: #ffffff;
      `
    case 'deprecated':
      return css`
        background-color: ${COLORS.SECONDARY_PINK};
        color: #ffffff;
      `
    case 'legacy':
      return css`
        background-color: ${COLORS.SECONDARY_YELLOW};
        color: #000;
      `

    default:
      return css`
        background-color: #ffffff;
        color: ${COLORS.PRIMARY_BLUE};
      `
  }
}

export const Flag = styled.span<{ children: string }>`
  padding: 2px 4px;
  text-transform: uppercase;
  font-weight: 300;
  font-style: italic;
  font-size: 9px;
  padding: 4px;
  max-width: 100%;
  border-radius: 2px;
  background-color: #ffffff;
  color: ${COLORS.PRIMARY_BLUE};

  ${({ children }) => flagColor(children)};
`
