import * as React from 'react'
import styled from 'styled-components'
import { LivePreview } from 'react-live'

import { COLORS } from '@ulule/owl-kit-components/dist/next.esm'

import { BackgroundPattern } from './BackgroundPattern'
import { CodeError } from './CodeError'

const CodePreviewWrapper = styled.div`
  margin: 32px 0;
`

const LivePreviewWrapper = styled.div<{ background: Background }>`
  background-color: ${props => (props.background === 'dark' ? `${COLORS.PRIMARY_BLACK}` : `${COLORS.PRIMARY_WHITE}`)};
  position: relative;
  width: 100%;
  padding: 32px;
  margin: 0;
  box-shadow: ${props => (props.background === 'light' ? '0px -1px 4px rgba(40, 44, 52, 0.12)' : 'none')};
  border-bottom: 0;
  display: flex;
  justify-content: center;

  & > svg {
    position: absolute;
    display: ${props => (props.background === 'transparent' ? 'flex' : 'none')};
    top: 0;
    left: 0;
  }
`

const StyledLivePreview = styled(LivePreview)`
  width: ${({ width }) => width}px;
  max-width: 100%;
  z-index: 1;
`

const ActionBar = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`

const ActionBarRight = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;

  select {
    font-size: 10px;
    border: 1px solid #e0e0e0;
    background: #ffffff;
  }

  select + select {
    margin-left: 4px;
  }
`

const Title = styled.span`
  font-size: 14px;
  font-weight: 300;
  font-style: italic;
`

export type Background = 'transparent' | 'light' | 'dark'

type BackgroundSelectProps = {
  onChange: (event: React.ChangeEvent<HTMLSelectElement> | React.FocusEvent<HTMLSelectElement>) => void
  value?: Background
}

function BackgroundSelect({ onChange, value }: BackgroundSelectProps): React.ReactElement<BackgroundSelectProps> {
  return (
    <select onChange={onChange} onBlur={onChange} value={value}>
      <option value="transparent">Transparent</option>
      <option value="light">Light</option>
      <option value="dark">Dark</option>
    </select>
  )
}

export type Viewport = '520' | '768' | '1024' | '1280'

type ViewportSelectProps = {
  onChange: (event: React.ChangeEvent<HTMLSelectElement> | React.FocusEvent<HTMLSelectElement>) => void
  value?: Viewport
}

function SizeSelect({ onChange, value }: ViewportSelectProps): React.ReactElement<ViewportSelectProps> {
  return (
    <select onChange={onChange} onBlur={onChange} value={value}>
      <option value="520">Phone</option>
      <option value="768">Tablet</option>
      <option value="1024">Laptop</option>
      <option value="1280">Desktop</option>
    </select>
  )
}

type PreviewPanelProps = {
  defaultViewport?: Viewport
  defaultBackground?: Background
  enableBackgroundChange?: boolean
  enableViewportChange?: boolean
  error?: string
  title?: string
  className?: string
}

export function CodePreview({
  defaultViewport = '1280',
  defaultBackground = 'transparent',
  enableBackgroundChange = false,
  enableViewportChange = false,
  error,
  title,
  className,
}: PreviewPanelProps): React.ReactElement<PreviewPanelProps> {
  const [viewport, setViewport] = React.useState(defaultViewport)
  const [background, setBackground] = React.useState(defaultBackground)

  return (
    <CodePreviewWrapper className={className}>
      <ActionBar>
        {title && <Title>{title}</Title>}
        <ActionBarRight>
          {enableBackgroundChange && (
            <BackgroundSelect onChange={event => setBackground(event.currentTarget.value as any)} value={background} />
          )}
          {enableViewportChange && (
            <SizeSelect onChange={event => setViewport(event.currentTarget.value as any)} value={viewport} />
          )}
        </ActionBarRight>
      </ActionBar>
      {error && <CodeError />}
      {!error && (
        <LivePreviewWrapper background={background}>
          <StyledLivePreview width={viewport} />
          <BackgroundPattern />
        </LivePreviewWrapper>
      )}
    </CodePreviewWrapper>
  )
}
