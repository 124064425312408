import * as React from 'react'
import { LiveProvider } from 'react-live'

import { CodeExample, CodeExampleProps } from './CodeExample'
import { CodePreview } from './CodePreview'

type CodeBlockProps = CodeExampleProps & {
  preview?: boolean
  scope: { [s: string]: any }
  className?: string
  children?: string
}

export function CodeBlock({
  children,
  preview,
  className,
  scope,
  ...props
}: CodeBlockProps): React.ReactElement<CodeBlockProps> {
  const language = className ? className.replace(/language-/, '') : ''

  if (preview) {
    return (
      <LiveProvider code={children} scope={scope} language={language as any} transformCode={code => `<>${code}</>`}>
        <CodePreview {...props} />
      </LiveProvider>
    )
  }

  return <CodeExample {...props} code={children} language={language as any} />
}
