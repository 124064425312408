import * as React from 'react'

import { GithubLogo } from '../../../components/Icons'
import { githubPath } from '../../../utils/githubPath'
import * as S from '../styles'

export function RightZone() {
  return (
    <S.RightZoneWrapper>
      <S.GithubLink href={githubPath('')} target="_blank" rel="noopener">
        <span>GitHub</span>
        <GithubLogo width={14} height={13} />
      </S.GithubLink>
    </S.RightZoneWrapper>
  )
}
