import * as React from 'react'
import styled from 'styled-components'
import { withLive } from 'react-live'
import Editor from 'react-simple-code-editor'
// @ts-ignore
import { highlight, languages } from 'prismjs/components/prism-core'

import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-markup'
import 'prismjs/components/prism-javascript'
import 'prismjs/components/prism-jsx'
import 'prismjs/components/prism-typescript'
import 'prismjs/components/prism-tsx'
import 'prismjs/components/prism-bash'

const StyledLiveEditor = styled.div`
  height: auto;
  padding: 16px;
  margin: 0;
  background-color: #282c34;
  color: #ffffff;
  overflow: auto;
  tab-size: 1.5em;
  font-size: 16px;
  font-family: 'Source Code Pro', monospace;
  line-height: 20px;
  white-space: pre-wrap;
  word-break: break-word;
  -webkit-overflow-scrolling: touch;

  & *:focus {
    outline: 0;
  }
`

const ActionBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 8px 0;

  button {
    font-size: 10px;
    border: 1px solid #e0e0e0;
    background: #fff;
  }
`

type CopyButtonProps = {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  clicked: boolean
}

const CopyButton = ({ onClick, clicked }: CopyButtonProps) => (
  <button onClick={onClick}>{clicked ? 'Copied!' : 'Copy snippet'}</button>
)

export type CodeEditorProps = {
  language?: 'clike' | 'markup' | 'javascript' | 'jsx' | 'bash' | 'typescript' | 'tsx'
  className?: string
  enableCopy?: boolean
  readOnly?: boolean
  code?: string
  initialCode?: string
  error?: string
  onChange?: (code: string) => void
  onError?: (error: string) => void
}

function ControlledCodeEditor({
  language = 'jsx',
  className,
  enableCopy = false,
  readOnly = false,
  code,
  error,
  onChange,
  onError,
}: CodeEditorProps): React.ReactElement<CodeEditorProps> {
  const [copied, setCopied] = React.useState(false)
  const timeoutHandleRef = React.useRef<any>(null)

  React.useEffect(() => {
    if (error && onError) {
      onError(error)
    }
  }, [error])

  return (
    <div className={className}>
      <StyledLiveEditor>
        <Editor
          readOnly={readOnly}
          value={code || ''}
          onValueChange={handleChange}
          highlight={() => highlight(code, languages[language])}
        />
      </StyledLiveEditor>
      {/* re-activate when clipboard is fixed */}
      {false && <ActionBar>{enableCopy && <CopyButton onClick={handleCopy} clicked={copied} />}</ActionBar>}
    </div>
  )

  function handleChange(code: string): void {
    //setCurrentCode(code)
    if (onChange) {
      onChange(code)
    }
  }

  function handleCopy(): void {
    // TODO : fix this
    navigator.permissions.query({ name: 'clipboard' }).then(result => {
      if (result.state === 'granted' || result.state === 'prompt') {
        navigator.clipboard.writeText(code||'').then(
          () => {
            setCopied(true)
            timeoutHandleRef.current = setTimeout(() => {
              setCopied(false)
              clearTimeout(timeoutHandleRef.current)
            }, 1500)
          },
          error => {
            console.error(error) // eslint-disable-line no-console
          },
        )
      }
    })
  }
}

function UncontrolledCodeEditor(props: CodeEditorProps) {
  const [code, setCode] = React.useState(props.initialCode || '')

  return <ControlledCodeEditor {...props} code={code} onChange={handleChange} />

  function handleChange(code: string): void {
    setCode(code)
    if (props.onChange) {
      props.onChange(code)
    }
  }
}

export const LiveCodeEditor = withLive(ControlledCodeEditor)
export const CodeEditor = UncontrolledCodeEditor
