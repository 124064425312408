import styled from 'styled-components'
import { COLORS } from '@ulule/owl-kit-components/dist/next.esm'

export const Blockquote = styled.div`
  border-left: 3px solid ${COLORS['GREY_SHADE_4']};
  color: ${COLORS['GREY_SHADE_4']};
  padding: 5px 0 5px 20px;

  a {
    color: inherit;
    text-decoration: underline;

    &:hover {
      color: ${COLORS['GREY_SHADE_2']};
    }
  }
`

export const Paragraph = styled.p`
  color: inherit;
  font-size: 16px;
  line-height: 1.8;
  margin: 10px 0;

  a {
    color: ${COLORS['PRIMARY_BLUE']};
    text-decoration: none;

    :hover {
      text-decoration: underline;
    }
  }
`
