import * as React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'

import { Close } from '../../components/Icons'
import { ComponentCard } from '../../components/ComponentCard'
import { SearchInput } from './SearchInput'
import { githubPath } from '../../utils/githubPath'

import * as S from './styles'
import { Data, Node } from './types'

type ResultsProps = { list: Node[] }

function Results({ list }: ResultsProps): React.ReactElement<ResultsProps> {
  return (
    <S.ComponentsList>
      {list.map(({ id, frontmatter, fields }) => (
        <li key={id}>
          <ComponentCard
            slug={fields.slug}
            flag={frontmatter.flag}
            dark
            name={frontmatter.displayName}
            subgroup={frontmatter.subgroup}
            disabled={frontmatter.disabled}
          />
        </li>
      ))}
    </S.ComponentsList>
  )
}

function useSearch(): [Node[], (search: string) => void] {
  const [search, setSearch] = React.useState('')

  const data = useStaticQuery<Data>(graphql`
    query {
      allMdx(filter: { frontmatter: { displayName: { ne: null } } }) {
        edges {
          node {
            id
            frontmatter {
              displayName
              group
              subgroup
              flag
              disabled
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `)

  const regexp = new RegExp(search, 'gi')

  const results = data.allMdx.edges
    .filter(({ node: { frontmatter } }) => {
      return search.length === 0 || frontmatter.displayName.match(regexp) || frontmatter.subgroup?.match(regexp)
    })
    .map(edge => edge.node)
    .sort((a, b) => {
      return a.frontmatter.displayName.localeCompare(b.frontmatter.displayName)
    })

  return [results, setSearch]
}

type MenuProps = {
  onClickClose: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export function Menu({ onClickClose }: MenuProps): React.ReactElement<MenuProps> {
  const [results, setSearch] = useSearch()
  return (
    <S.MenuWrapper>
      <S.MenuSide>
        <S.MenuSideSection>
          <S.MenuTitle>Guide</S.MenuTitle>
          <S.PagesList>
            <li>
              <Link to="/getting-started">Getting started</Link>
            </li>
            <li>
              <Link to="/how-to-use-it">How to use it?</Link>
            </li>
            <li>
              <Link to="/contributing">Contributing</Link>
            </li>
            <li>
              <Link to="/faq">FAQs</Link>
            </li>
          </S.PagesList>
        </S.MenuSideSection>
        <S.MenuSideSection>
          <S.MenuTitle>Identity</S.MenuTitle>
          <S.PagesList>
            <li>
              <Link to="/identity/logotypes">Logotypes</Link>
            </li>
            <li>
              <Link to="/identity/colors">Colors</Link>
            </li>
            <li>Iconography</li>
            <S.NestedPageList>
              <li>
                <Link to="/identity/icons/glyphs">Glyphs</Link>
              </li>
              <li>
                <Link to="/identity/icons/illustrations">Illustrations</Link>
              </li>
            </S.NestedPageList>
            <li>Typography</li>
            <S.NestedPageList>
              <li>
                <Link to="/identity/typography/families">Families</Link>
              </li>
              <li>
                <Link to="/identity/typography/headings">Headings</Link>
              </li>
              <li>
                <Link to="/identity/typography/copy">Copy</Link>
              </li>
            </S.NestedPageList>
          </S.PagesList>
        </S.MenuSideSection>
      </S.MenuSide>
      <S.MenuMain>
        <S.MenuTitle>Components</S.MenuTitle>
        <SearchInput
          type="text"
          placeholder="Type the name of a component or a group of components"
          onChange={setSearch}
          isSearching={false}
        />
        <S.ResultsWrapper>
          {results.length > 0 ? (
            <Results list={results} />
          ) : (
            <p>
              No results{' '}
              <span role="img" aria-label="cry">
                😭
              </span>
            </p>
          )}
        </S.ResultsWrapper>
      </S.MenuMain>
      <S.MenuButton onClick={onClickClose}>
        <Close width={32} height={32} />
      </S.MenuButton>
    </S.MenuWrapper>
  )
}
