import * as React from 'react'
import styled, { css, ThemeProvider } from 'styled-components'
import { Link } from 'gatsby'

import { COLORS } from '@ulule/owl-kit-components/dist/next.esm'
import { Flag } from './Flag'

function getCardColor(theme: string) {
  if (theme === 'dark') {
    return css`
      background-color: transparent;
      color: #ffffff;
      border: 3px solid #ffffff;

      &:hover {
        background-color: #ffffff;
        color: ${COLORS.PRIMARY_BLUE_HOVER};
      }
    `
  } else {
    return css`
      background-color: ${COLORS.PRIMARY_BLUE};
      color: #ffffff;

      &:hover {
        background-color: ${COLORS.PRIMARY_BLUE_HOVER};
      }
    `
  }
}

const MainWrapper = styled(Link)<{ theme: { main: string }; disabled?: boolean }>`
  position: relative;
  display: block;
  flex: 1;
  width: 100%;
  padding-bottom: 56.25%;
  border-radius: 3px;
  text-decoration: none;
  ${({ theme }) => getCardColor(theme.main)};
  transition: all 250ms ease-in-out;

  &[disabled] {
    pointer-events: none;
    opacity: 0.2;
  }
`

const ContentWrapper = styled.div`
  width: 100%;
  padding: 8px 16px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  align-items: flex-start;
`

const Name = styled.div`
  font-size: 14px;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`

const Group = styled.span<{ theme: { main: string } }>`
  color: ${COLORS.BLUE_SHADE_1};
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 4px;
  transition: all 250ms ease-in-out;

  ${MainWrapper}:hover & {
    ${({ theme }) => theme.main === 'dark' && `color: ${COLORS.PRIMARY_BLUE};`};
  }
`

const CardFlag = styled(Flag)`
  display: block;
  position: absolute;
  bottom: 8px;
  right: 8px;
`

type ComponentCardProps = {
  slug: string
  flag?: string
  dark?: boolean
  name: string
  subgroup?: string
  disabled?: boolean
}

export function ComponentCard({
  slug,
  disabled,
  dark,
  flag = '',
  subgroup = '',
  name,
}: ComponentCardProps): React.ReactElement<ComponentCardProps> {
  const theme = {
    main: dark ? 'dark' : 'light',
  }

  return (
    <ThemeProvider theme={theme}>
      <MainWrapper to={slug} disabled={disabled}>
        <ContentWrapper>
          <Group>{subgroup}</Group>
          <Name>{name}</Name>
        </ContentWrapper>
        {flag && <CardFlag>{flag}</CardFlag>}
      </MainWrapper>
    </ThemeProvider>
  )
}
