import * as React from 'react'
import styled from 'styled-components'

import { COLORS, Spinner } from '@ulule/owl-kit-components/dist/next.esm'

import { Search as SearchIcon, Cancel } from '../../components/Icons'

const ResetButton = styled(Cancel)`
  cursor: pointer;

  :hover {
    color: ${COLORS.PRIMARY_BLACK};
  }
`

const InputWrapper = styled.div`
  position: relative;
  background-color: rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row-reverse;
  height: 50px;
  width: 100%;
  padding: 8px 16px;
  border: 0;
  border-radius: 3px;

  svg {
    color: rgba(255, 255, 255, 0.4);
  }

  input {
    height: 100%;
    background: transparent;
    color: rgba(255, 255, 255, 1);
    font-weight: 300;
    border: 0;
    flex: 1;

    ::placeholder {
      color: rgba(255, 255, 255, 0.4);
      font-weight: 300;
    }

    :focus {
      outline: 0;
    }
  }
`

const detectMobile = () => {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true
  } else {
    return false
  }
}

type SearchInputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> & {
  onChange: (value: string) => void
  isSearching: boolean
}

export function SearchInput({
  onChange,
  isSearching,
  value,
  ...props
}: SearchInputProps): React.ReactElement<SearchInputProps> {
  const inputRef = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    // autofocus if not mobile
    if (!detectMobile()) {
      inputRef.current?.focus()
    }
  }, [])

  return (
    <InputWrapper onClick={() => inputRef.current?.focus()}>
      {isSearching ? <Spinner small /> : value ? <ResetButton onClick={() => onChange('')} /> : <SearchIcon />}
      <input
        type="text"
        onChange={event => onChange(event.currentTarget.value)}
        value={value}
        {...props}
        ref={inputRef}
      />
    </InputWrapper>
  )
}
