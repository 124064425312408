import * as React from 'react'
import styled from 'styled-components'
import { LiveError } from 'react-live'

import { ErrorOutline } from '../Icons'

const ErrorBlock = styled.div`
  background: #ffffff;
`

const ErrorBlockContent = styled(LiveError)`
  padding: 32px 16px;
  border-left: 2px dashed #ff4336;
  border-right: 2px dashed #ff4336;
  font-size: 14px;
  font-family: 'Source Code Pro', monospace;
  white-space: pre-wrap;
`

const ErrorBlockHead = styled.div`
  border: 2px dashed #ff4336;
  font-size: 12px;
  text-transform: uppercase;
  color: #ff4336;
  font-weight: 700;
  padding: 8px 16px;
  display: flex;
  align-items: center;

  svg {
    color: currentColor;
    margin-right: 8px;
  }
`

export function CodeError(): React.ReactElement {
  return (
    <ErrorBlock>
      <ErrorBlockHead>
        <ErrorOutline /> Error
      </ErrorBlockHead>
      <ErrorBlockContent />
    </ErrorBlock>
  )
}
